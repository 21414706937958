.ui-box {
  color: #edfd07;
  text-shadow: 1px 1px 1px black;
  top: 75%;
  left: 40%;
  width: 350px;
  z-index: 999 !important; /* Ensure the button is above the blurred map */
}

.guess-btn-container {
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  max-width: 250px;
}

.guess-btn-container:hover {
  filter: brightness(80%);
}

.guess-btn {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  text-shadow: 1px 1px 1px black;
  color: #edfd07;
}

.below-map {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
