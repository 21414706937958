.home-btn-container {
  background-image: url('https://storage.googleapis.com/jingle-media/homeButton.png');
  position: absolute;
  width: 44px;
  height: 30px;
  left: 10px;
  top: 76px;
}

.home-btn-container:hover {
  cursor: pointer;
  opacity: 0.8;
}
