.footer {
  background-image: url('https://storage.googleapis.com/jingle-media/osrsButtonCredits.png') !important;
  z-index: 999 !important; /* Ensure the button is above the blurred map */
  padding: 1.3% 0;
  color: #edfd07;
  box-shadow: 0px 1px 2px black;
  font-size: 14px;
  font-style: italic;
}

.link {
  color: #f2fe51;
  text-decoration: none;
  transition: text-decoration 0.3s;
}

.link:hover {
  text-decoration: underline;
  color: #f6fe85 !important;
}

.icon {
  padding: 2%;
  width: 80px;
  color: #f2fe51;
  font-size: 19px;
}

.icon:hover {
  color: #f6fe85 !important;
}
