@font-face {
  font-family: 'Runescape UF';
  src: url('../public/fonts/runescape.ttf');
}

.App {
  text-align: center;
  font-family: 'Runescape UF';
}

.App-inner {
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  padding-bottom: 5vh;
}

.button {
  margin: 0 0 20px;
  width: 250px;
  border-radius: 30px !important;
  font-style: italic;
}

.button {
  margin: 0 0 20px;
  width: 250px;
  border-radius: 30px !important;
  font-style: italic;
}

.blur {
  filter: blur(40px); /* Adjust the blur strength as needed */
  border: 0px solid transparent;
}
